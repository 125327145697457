import { error } from 'pdf-lib';

const TextInputRegister = ({
  label,
  name,
  register,
  errors,
  password = null,
}) => {


  const handleError = mensaje => {
    switch (name) {
      case 'name':
        return 'Name is required';
        break;
      case 'fatherLastName':
        return 'Last name is required';
        break;
      
      case 'professionalID':
        return 'Professional license is required';
        break;
      case 'phone':
        return 'Phone is required';
        break;
      case 'email':
        return 'Email is required';
        break;
      case 'confirmEmail':
        return 'The Email Confirmation must be the same as the Email';
        break;
      case 'password':
        return 'Password is required';
        break;
      case 'confirmPassword':
        return 'Password Confirmation must be equal to Password';
        break;
    }
  };

  return (
    <>
      <label htmlFor={name} className="text-sm text-gray-600 mb-2 block">
        {label}
      </label>

      <input
        type={`${password ? 'password' : 'text'}`}
        name={name}
        id={name}
        className="w-full py-2 px-4 shadow-sm focus:ring-deep-sky-blue focus:border-deep-sky-blue border-gray-300 rounded-lg"
        ref={register}
      />
      {errors[name]?.message && (
        <p className="text-xs text-red-400 pl-5 mt-1">
          {handleError(errors[name]?.message)}
        </p>
      )}
    </>
  );
};

export default TextInputRegister;
